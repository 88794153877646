/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router';

// import pages
const Home = () => import('@/pages/Home.vue');
const HomeEcommerce = () => import('@/pages/HomeEcommerce.vue');
const HomeProject = () => import('@/pages/HomeProject.vue');
const HomeMarketing = () => import('@/pages/HomeMarketing.vue');
const HomeNft = () => import('@/pages/HomeNft.vue');

const NotFound = () => import('@/pages/error/NotFound.vue');

// ui components
const Accordion = () => import('@/pages/components/Accordion');
const Alert = () => import('@/pages/components/Alert');
const Badge = () => import('@/pages/components/Badge');
const Breadcrumb = () => import('@/pages/components/Breadcrumb');
const Buttons = () => import('@/pages/components/Buttons');
const ButtonGroup = () => import('@/pages/components/ButtonGroup');
const Cards = () => import('@/pages/components/Cards');
const Carousel = () => import('@/pages/components/Carousel');
const CloseButton = () => import('@/pages/components/CloseButton');
const Collapse = () => import('@/pages/components/Collapse');
const Dropdown = () => import('@/pages/components/Dropdown');
const ListGroup = () => import('@/pages/components/ListGroup');
const Modal = () => import('@/pages/components/Modal');
const Tabs = () => import('@/pages/components/Tabs');
const Offcanvas = () => import('@/pages/components/Offcanvas');
const Pagination = () => import('@/pages/components/Pagination');
const Placeholders = () => import('@/pages/components/Placeholders');
const Popovers = () => import('@/pages/components/Popovers');
const Progress = () => import('@/pages/components/Progress');
const Spinners = () => import('@/pages/components/Spinners');
const Toasts = () => import('@/pages/components/Toasts');
const Tooltips = () => import('@/pages/components/Tooltips');

// utilities
const Misc = () => import('@/pages/utilities/Misc');
const Typography = () => import('@/pages/utilities/Typography');
const Image = () => import('@/pages/utilities/Image');
const Tables = () => import('@/pages/utilities/Tables');
const Background = () => import('@/pages/utilities/Background');
const Borders = () => import('@/pages/utilities/Borders');
const Colors = () => import('@/pages/utilities/Colors');
const Flex = () => import('@/pages/utilities/Flex');
const Sizing = () => import('@/pages/utilities/Sizing');
const Spacing = () => import('@/pages/utilities/Spacing');

// forms
const FormControl = () => import('@/pages/forms/FormControl');
const Select = () => import('@/pages/forms/Select');
const ChecksRadios = () => import('@/pages/forms/ChecksRadios');
const Range = () => import('@/pages/forms/Range');
const InputGroup = () => import('@/pages/forms/InputGroup');
const FloatingLabels = () => import('@/pages/forms/FloatingLabels');
const Layout = () => import('@/pages/forms/Layout');
const Validation = () => import('@/pages/forms/Validation');
const DateTime = () => import('@/pages/forms/DateTime');
const FormUpload = () => import('@/pages/forms/FormUpload');
const Quill = () => import('@/pages/forms/editors/Quill');
const Tinymce = () => import('@/pages/forms/editors/Tinymce');

// layout
const Breakpoints = () => import('@/pages/layout/Breakpoints');
const Containers = () => import('@/pages/layout/Containers');
const Gutters = () => import('@/pages/layout/Gutters');

//const pages
const DataTables = () => import('@/pages/DataTables');
const Charts = () => import('@/pages/Charts');
const Sweetalert = () => import('@/pages/Sweetalert');

// apps apages
const Calendar = () => import('@/pages/apps/fullcalendar/Calendar');
const KanbanBoard = () => import('@/pages/apps/kanban/KanbanBoard');
const KanbanBoardCustom = () => import('@/pages/apps/kanban/KanbanBoardCustom');
const Chats = () => import('@/pages/apps/chat/Chats');
const Inbox = () => import('@/pages/apps/mailbox/Inbox');

const UserList = () => import('@/pages/user-manage/UserList');
const UserCards = () => import('@/pages/user-manage/UserCards');
const UserProfile = () => import('@/pages/user-manage/UserProfile');
const UserEdit = () => import('@/pages/user-manage/UserEdit');

//Profile pages
const Profile = () => import('@/pages/Profile');
const MyContacts = () => import('@/pages/MyContacts');
const ProfileSettings = () => import('@/pages/ProfileSettings');

// ecommerce pages
const Products = () => import('@/pages/ecommerce/Products');
const Categories = () => import('@/pages/ecommerce/Categories');
const AddProduct = () => import('@/pages/ecommerce/AddProduct');
const EditProduct = () => import('@/pages/ecommerce/EditProduct');
const AddCategory = () => import('@/pages/ecommerce/AddCategory');
const EditCategory = () => import('@/pages/ecommerce/EditCategory');

// auths pages
const AuthRegister = () => import('@/pages/auths/AuthRegister');
const AuthLogin = () => import('@/pages/auths/AuthLogin');
const AuthReset = () => import('@/pages/auths/AuthReset');

const Icons = () => import('@/pages/Icons');


const routes = [
  {
    path: '/',
    name: 'Analytics',
    component: Home,
    alias: '/home'
  },
  {
    path: '/home-ecommerce',
    name: 'eCommerce',
    component: HomeEcommerce
  },
  {
    path: '/home-project',
    name: 'Project Manage',
    component: HomeProject
  },
  {
    path: '/home-marketing',
    name: 'Marketing',
    component: HomeMarketing
  },
  {
    path: '/home-nft',
    name: 'NFT',
    component: HomeNft
  },
  {
    // start ui element here
    path: '/components/accordion',
    name: 'Accordion',
    component: Accordion
  },
  {
    path: '/components/alerts',
    name: 'Alert',
    component: Alert
  },
  {
    path: '/components/badge',
    name: 'Badge',
    component: Badge
  },
  {
    path: '/components/breadcrumb',
    name: 'Breadcrumb',
    component: Breadcrumb
  },
  {
    path: '/components/buttons',
    name: 'Buttons',
    component: Buttons
  },
  {
    path: '/components/button-group',
    name: 'Button group',
    component: ButtonGroup
  },
  {
    path: '/components/cards',
    name: 'Cards',
    component: Cards
  },
  {
    path: '/components/carousel',
    name: 'Carousel',
    component: Carousel
  },
  {
    path: '/components/close-button',
    name: 'Close button',
    component: CloseButton
  },
  {
    path: '/components/collapse',
    name: 'Collapse',
    component: Collapse
  },
  {
    path: '/components/dropdown',
    name: 'Dropdowns',
    component: Dropdown
  },
  {
    path: '/components/list-group',
    name: 'List group',
    component: ListGroup
  },
  {
    path: '/components/modal',
    name: 'Modal',
    component: Modal
  },
  {
    path: '/components/tabs',
    name: 'Tabs',
    component: Tabs
  },
  {
    path: '/components/offcanvas',
    name: 'Offcanvas',
    component: Offcanvas
  },
  {
    path: '/components/pagination',
    name: 'Pagination',
    component: Pagination
  },
  {
    path: '/components/placeholders',
    name: 'Placeholders',
    component: Placeholders
  },
  {
    path: '/components/popovers',
    name: 'Popovers',
    component: Popovers
  },
  {
    path: '/components/progress',
    name: 'Progress',
    component: Progress
  },
  {
    path: '/components/spinners',
    name: 'Spinners',
    component: Spinners
  },
  {
    path: '/components/toasts',
    name: 'Toasts',
    component: Toasts
  },
  {
    path: '/components/tooltips',
    name: 'Tooltips',
    component: Tooltips
  },
  {
    // utilities start here
    path: '/utilities/misc',
    name: 'Misc',
    component: Misc
  },
  {
    path: '/utilities/typography',
    name: 'Typography',
    component: Typography
  },
  {
    path: '/utilities/image',
    name: 'Image',
    component: Image
  },
  {
    path: '/utilities/tables',
    name: 'Tables',
    component: Tables
  },
  {
    path: '/utilities/background',
    name: 'Background',
    component: Background
  },
  {
    path: '/utilities/borders',
    name: 'Borders',
    component: Borders
  },
  {
    path: '/utilities/colors',
    name: 'Colors',
    component: Colors
  },
  {
    path: '/utilities/flex',
    name: 'Flex',
    component: Flex
  },
  {
    path: '/utilities/sizing',
    name: 'Sizing',
    component: Sizing
  },
  {
    // utilities end here
    path: '/utilities/spacing',
    name: 'Spacing',
    component: Spacing
  },
  {
    // forms start here
    path: '/forms/form-control',
    name: 'Form controls',
    component: FormControl
  },
  {
    path: '/forms/form-select',
    name: 'Select',
    component: Select
  },
  {
    path: '/forms/checks-radios',
    name: 'Checks radios',
    component: ChecksRadios
  },
  {
    path: '/forms/form-range',
    name: 'Range',
    component: Range
  },
  {
    path: '/forms/input-group',
    name: 'Input group',
    component: InputGroup
  },
  {
    path: '/forms/floating-labels',
    name: 'Floating labels',
    component: FloatingLabels
  },
  {
    path: '/forms/form-layout',
    name: 'Layout',
    component: Layout
  },
  {
    path: '/forms/form-validation',
    name: 'Validation',
    component: Validation
  },
  {
    path: '/forms/date-time',
    name: 'Date time',
    component: DateTime
  },
  {
    path: '/forms/form-upload',
    name: 'Form Upload',
    component: FormUpload
  },
  {
    path: '/forms/editors/quill',
    name: 'Quill Editor',
    component: Quill
  },
  {
    path: '/forms/editors/tinymce',
    name: 'Tinymce Editor',
    component: Tinymce
  },
  {
    // start layout here
    path: '/layout/breakpoints',
    name: 'Breakpoints',
    component: Breakpoints
  },
  {
    path: '/layout/containers',
    name: 'Containers',
    component: Containers
  },
  {
    // end layout
    path: '/layout/gutters',
    name: 'Gutters',
    component: Gutters
  },
  {
    path: '/data-tables',
    name: 'Data Tables',
    component: DataTables
  },
  {
    path: '/charts',
    name: 'Chart Js',
    component: Charts
  },
  {
    path: '/sweetalert',
    name: 'Sweetalert',
    component: Sweetalert
  },
  {
    path: '/apps/chats',
    name: 'Message | Chats',
    component: Chats
  },
  {
    path: '/apps/inbox',
    name: 'Inbox',
    component: Inbox
  },
  {
    path: '/apps/calendar',
    name: 'Calendar',
    component: Calendar
  },
  {
    path: '/apps/kanban/basic',
    name: 'Kanban board',
    component: KanbanBoard
  },
  {
    path: '/apps/kanban/custom',
    name: 'Kanban board custom',
    component: KanbanBoardCustom
  },
  {
    // start user mange pages
    path: '/user-manage/user-list',
    name: 'User list',
    component: UserList,
  },
  {
    path: '/user-manage/user-Cards',
    name: 'Users Cards',
    component: UserCards
  },
  {
    path: '/user-manage/user-profile/:id',
    name: 'User Profile',
    component: UserProfile
  },
  {
    path: '/user-manage/user-edit/:id',
    name: 'User Edit',
    component: UserEdit
  },
  {
    path: '/profile',
    name: 'My Profile',
    component: Profile
  },
  {
    path: '/my-contacts',
    name: 'My Contact',
    component: MyContacts
  },
  {
    path: '/profile-settings',
    name: 'Edit Settings',
    component: ProfileSettings
  },
  {
    // start ecommerce pages
    path: '/ecommerce/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/ecommerce/categories',
    name: 'Categories',
    component: Categories
  },
  {
    path: '/ecommerce/add-product',
    name: 'Add Product',
    component: AddProduct
  },
  {
    path: '/ecommerce/edit-product/:id',
    name: 'Edit Product',
    component: EditProduct
  },
  {
    path: '/ecommerce/add-category',
    name: 'Add Category',
    component: AddCategory
  },
  {
    // end ecommerce pages
    path: '/ecommerce/edit-category/:id',
    name: 'Edit Category',
    component: EditCategory
  },
  {
    path: '/auths/auth-register',
    name: 'Auth Register',
    component: AuthRegister
  },
  {
    path: '/auths/auth-login',
    name: 'Auth Login',
    component: AuthLogin
  },
  {
    path: '/auths/auth-reset',
    name: 'Forgot Password',
    component: AuthReset
  },
  {
    path: '/icons',
    name: 'Nioicon',
    component: Icons
  },
  { path: '/:pathMatch(.*)*', 
    name: 'Page NotFound', 
    component: NotFound 
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  }
})

// this function dynamically change the route title of app and Redirect user to login page if not logged in
router.beforeEach((to, from, next) => {
  document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`
  const publicRoutes = ['/auths/auth-register', '/auths/auth-login','/auths/auth-reset'];
  const isAuthenticated = localStorage.getItem('nioBoardAuthenticated')
  if(publicRoutes.includes(to.path) || isAuthenticated){
    next();
  }else{
    next('/auths/auth-login');
  }
})

export default router
